import React, { useEffect, useState } from "react"
import isNumber from "lodash/isNumber"
import mapValues from "lodash/mapValues"
import flatten from "lodash/flatten"
import uniqBy from "lodash/uniqBy"
import sortBy from "lodash/sortBy"
import compact from "lodash/compact"
import range from "lodash/range"
import sample from "lodash/sample"
import { Button } from "reactstrap"
import { useDispatch, useSelector } from "react-redux"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Sequences_sequences } from "../hasura/queries/types/Sequences"
import { createCrosswordAction, curriculumSelector, CurriculumState } from "../hasura/slices/curriculum"
import { fetchSequencesAction, sequenceSelector, SequenceState } from "../hasura/slices/sequence"
import { hasWindow, imageFor, searchQueryParams } from "../lib/helpers"

const imageDimension = 120
const cellDimension = 32

const rectanglesIntersect = (
  minAx: number,
  minAy: number,
  maxAx: number,
  maxAy: number,
  minBx: number,
  minBy: number,
  maxBx: number,
  maxBy: number
) => {
  const aLeftOfB = maxAx < minBx
  const aRightOfB = minAx > maxBx
  const aAboveB = minAy > maxBy
  const aBelowB = maxAy < minBy

  return !(aLeftOfB || aRightOfB || aAboveB || aBelowB)
}

export default function Crossword() {
  const dispatch = useDispatch()

  const { crossword }: CurriculumState = useSelector(curriculumSelector)
  const { sequences }: SequenceState = useSelector(sequenceSelector)

  const [sequence, setSequence] = useState<Sequences_sequences | undefined>()
  const [displayAnswers, setDisplayAnswers] = useState(false)
  const [imagesWithCoordinates, setImagesWithCoordinates] = useState<any[]>([])

  const isHeadless = hasWindow && /\bHeadlessChrome\//.test(navigator.userAgent)

  useEffect(() => {
    dispatch(fetchSequencesAction(""))
  }, [])

  useEffect(() => {
    if (!sequences.length) return

    const id = parseInt(searchQueryParams("id") || "", 10)
    generateNewCrossword(id)
  }, [sequences])

  useEffect(() => {
    if (!crossword) return

    const images = compact(flatten(sequence?.sequence_items.map((i) => sample(i.concept?.concept_image_appearances)?.image.s3_path))).map(imageFor)
    const cells = Array.from(document.getElementsByClassName("crossword-cell"))
    const container = document.getElementById("container")
    if (!cells.length || !container) return

    console.log(`layout ${images.length} images / avoid ${cells.length} cells`)
    const { x, y, width, height } = container.getBoundingClientRect()

    const avoid = cells.map((cell) => {
      const rect = cell.getBoundingClientRect()
      const left = rect.x - x
      const top = rect.y - y
      return [left, top, left + rect.width, top + rect.height]
    })

    const randomCoordinates = () => [Math.round(Math.random() * (width - imageDimension)), Math.round(Math.random() * (height - imageDimension))]

    const withCoordinates = images
      .map((source) => {
        let coordinates: number[]

        let counter = 0

        while (true && counter < 100) {
          const test = randomCoordinates()
          if (avoid.some((a) => rectanglesIntersect(test[0], test[1], test[0] + imageDimension, test[1] + imageDimension, a[0], a[1], a[2], a[3]))) {
            counter += 1
          } else {
            coordinates = test
            avoid.push(test)
            break
          }
        }

        // @ts-ignore
        return { source, coordinates }
      })
      .filter((i) => i.coordinates)

    setImagesWithCoordinates(withCoordinates)
  }, [crossword])

  const generateNewCrossword = (id?: number) => {
    setImagesWithCoordinates([])
    const sequence = id
      ? sequences.find((_, idx) => idx + 1 === id)!
      : sample(sequences.filter((s) => range(8, 16).includes(s.sequence_items.length)))!
    setSequence(sequence)
    dispatch(createCrosswordAction(sequence))
  }

  const hints = sortBy(
    uniqBy(
      flatten(
        compact(
          sequence?.sequence_items.map((i) =>
            i.concept?.root_appearances.map((r) => ({
              display_name: r.value,
              definition: i.concept?.definition?.slice(r.definition_start_index, r.definition_end_index + 1),
            }))
          )
        )
      ),
      (r) => `${r.display_name}-${r.definition}`
    ),
    "definition"
  )

  return (
    <Layout noHeader noStyles>
      <SEO title="Crossword" />

      <div className="d-flex flex-column align-items-center justify-content-between vh-100 vw-100 p-3">
        <div className="d-flex justify-content-between align-items-start w-100 mb-3">
          <h1 className="text-xl text--gray8">
            <span className="text--secondary bold mr-3">WORDCRAFT</span>
            Latin Crossword {sequences.map((s) => s.display_name).indexOf(sequence?.display_name || "") + 1}
          </h1>

          <div className="d-flex">
            <Button
              style={{ visibility: isHeadless ? "hidden" : "visible" }}
              id="show-answers"
              onClick={() => setDisplayAnswers(!displayAnswers)}
              outline
              color="primary"
            >
              {displayAnswers ? "Hide" : "Show"} answers
            </Button>

            <Button style={{ visibility: isHeadless ? "hidden" : "visible" }} className="ml-2" onClick={() => generateNewCrossword()} color="success">
              New crossword
            </Button>

            {isHeadless && (
              <p className="m-0 text-s text--gray7">
                more games at <span className="text--primary bold">playwordcraft.com</span>
              </p>
            )}
          </div>
        </div>

        <div style={{ flexGrow: 1 }} className="d-flex w-100">
          <div className="d-flex flex-column justify-content-between align-items-start pb-2">
            <div style={{ maxWidth: "375px" }} className="mr-5">
              <div className="mb-3">
                <h4 className="text-xxs bold mb-0">DOWN</h4>

                {crossword?.legend.down.map((clue, idx) => (
                  <div key={idx} className="d-flex align-items-start">
                    <p style={{ minWidth: "25px" }} className="text-xs mb-0">
                      {clue[0]}.
                    </p>

                    <p className="m-0 text-xs">{clue[1]}</p>
                  </div>
                ))}
              </div>

              <div>
                <h4 className="text-xxs bold mb-0">ACROSS</h4>

                {crossword?.legend.across.map((clue, idx) => (
                  <div key={idx} className="d-flex align-items-start">
                    <p style={{ minWidth: "25px" }} className="text-xs mb-0">
                      {clue[0]}.
                    </p>

                    <p className="m-0 text-xs">{clue[1]}</p>
                  </div>
                ))}
              </div>
            </div>

            <div className="p-2 border border--gray8">
              <h4 className="text-xxs bold mb-0">HINTS</h4>

              <div
                style={{
                  gridTemplateColumns: `repeat(2, minmax(100px, 175px))`,
                  gridTemplateRows: `repeat(${Math.ceil(hints.length / 2)}, 1fr)`,
                  gridAutoFlow: "column",
                  display: "grid",
                }}
              >
                {sortBy(hints, "definition").map((hint, idx) => (
                  <p className="m-0 text-xs" key={idx}>
                    <span className="mr-2 font-italic">{hint.definition}</span>
                    {hint.display_name.toUpperCase()}
                  </p>
                ))}
              </div>
            </div>
          </div>

          <div id="container" className="position-relative" style={{ flexGrow: 1 }}>
            {imagesWithCoordinates.map((image, idx) => {
              const top = `${image.coordinates[1]}px`
              const left = `${image.coordinates[0]}px`
              return (
                <img
                  style={{ top, left, maxHeight: `${imageDimension}px`, maxWidth: `${imageDimension}px` }}
                  className="position-absolute"
                  key={idx}
                  src={image.source}
                />
              )
            })}

            {crossword?.grid.map((row, idx) => {
              const overlaps = (rowIdx: number, columnIdx: number) =>
                range(row.length).includes(rowIdx) && range(crossword.grid.length).includes(columnIdx) && crossword.grid[rowIdx][columnIdx] !== "-"

              return (
                <div className="d-flex" key={idx}>
                  {row.map((cell, idx2) => {
                    const hasValue = cell !== "-"
                    const display = hasValue && (displayAnswers || cell.toUpperCase() === cell)

                    const borders = {
                      borderTop: { color: hasValue ? "black" : "transparent", weight: overlaps(idx - 1, idx2) ? 0.5 : 1 },
                      borderBottom: { color: hasValue ? "black" : "transparent", weight: overlaps(idx + 1, idx2) ? 0.5 : 1 },
                      borderLeft: { color: hasValue ? "black" : "transparent", weight: overlaps(idx, idx2 - 1) ? 0.5 : 1 },
                      borderRight: { color: hasValue ? "black" : "transparent", weight: overlaps(idx, idx2 + 1) ? 0.5 : 1 },
                    }

                    const number = crossword.display[idx][idx2]

                    return (
                      <div
                        key={idx2}
                        style={{
                          width: `${cellDimension}px`,
                          height: `${cellDimension}px`,
                          ...mapValues(borders, (b) => `${b.weight}px solid ${b.color}`),
                          margin: "-0.5px",
                        }}
                        className={`flex-center position-relative ${hasValue ? "crossword-cell" : ""}`}
                      >
                        {isNumber(number) && (
                          <p style={{ lineHeight: "14px", left: "1px" }} className="text-xxs position-absolute t-0 m-0">
                            {number}
                          </p>
                        )}
                        <p className="m-0 text-m text-uppercase">{display ? cell : ""}</p>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}
